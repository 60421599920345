import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Backdrop, Fade, Modal, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#fff',
    width: "300px",
};
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="200"
                height="160"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Empty set</Box>
        </StyledGridOverlay>
    );
}
export default function BrandDataGrid({ brands,handleClick,setSnackType,setSnackMessage,setGridBrands,setBrands,t }) {
    const [currentRow, setCurrentRow] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [balance , setBalance ] = useState('');
    const [title , setTitle] = useState('');
    const [platformTitle , setPlatformTitle] = useState('');
    const [platformDomain , setPlatformDomain] = useState('');
    const handleOpenModal = (row) => {
        setOpenModal(true)
        setCurrentRow(row)
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentRow(null)
    };
    useEffect(()=>{
        if(currentRow){
            setBalance(currentRow.Balance)
            setTitle(currentRow.Title)
            setPlatformTitle(currentRow.PlatformTitle)
            setPlatformDomain(currentRow.PlatformDomain)
        }
        if(!currentRow){
            setBalance('')
            setTitle('')
            setPlatformTitle('')
            setPlatformDomain('')
        }
    },[currentRow])
    const columns = [
        {
            field: 'Title',
            headerName: t('AdminBrand'),
            width: 150,
            editable: false,
        },
        {
            field: 'Balance',
            headerName: t('AdminBalance'),
            width: 100,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;
                return (
                    <span variant="outlined" color="secondary" size="small" >{currentRow.Balance} $</span>
                );
            },
        },
        {
            field: 'PlatformTitle',
            headerName: t('AdminPlatform'),
            width: 150,
            editable: false,
        },
        {
            field: 'PlatformDomain',
            headerName: t('AdminPlatformDomain'),
            width: 210,
            editable: false,
        },
        {
            field: 'action',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 120,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <button onClick={() => handleOpenModal(currentRow)} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }} variant="outlined" color="secondary" size="small" >{t('AdminEdit')}</button>
                );
            },
        },
    ];
    const Edit = async () =>{
        if(!title){
            handleClick()
            setSnackType('error')
            setSnackMessage(t('AdminAddBrandSnackBarBrandError'))
            return
        }
        // if(!platformTitle){
        //     handleClick()
        //     setSnackType('error')
        //     setSnackMessage('Введите название платформы!')
        //     return
        // }
        // if(!platformDomain){
        //     handleClick()
        //     setSnackType('error')
        //     setSnackMessage('Введите домен платформы!')
        //     return
        // }
        // if(!balance){
        //     handleClick()
        //     setSnackType('error')
        //     setSnackMessage('Введите баланс бренда!')
        //     return
        // }
        try{
            const {data} = await axios.patch(`https://www.leadhubcrm.pro/api/brands/${currentRow?.Id}`,{
                Title:title,
                PlatformTitle:platformTitle,
                PlatformDomain:platformDomain,
                EndPoint:platformDomain,
                Balance : balance,
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                handleCloseModal()
                handleClick()
                setSnackType('success')
                setSnackMessage(t('AdminEditBrandSnackBarSuccess'))
                const brandsData = await axios.get('https://www.leadhubcrm.pro/api/brands', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                if (brandsData) {
                    setGridBrands(brandsData.data)
                    const arr = []
                    brandsData.data.forEach(el => {
                        arr.push({ value: el.Id, label: el.Title })
                    })
                    setBrands(arr)
                }
              }
        }catch(e){
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    return (
        <>
            <DataGrid
                rows={brands}
                autoHeight={true}

                columns={columns}
                getRowId={(row) => row.Id}
                initialState={{
                    pagination: {

                        paginationModel: {
                            pageSize: 4,
                        },
                    },
                }}
                sx={{
                    color:'#fff',
                    width: '100%',
                    minWidth:'400px',
                    fontFamily: "'Montserrat',sans-serif",
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    '& .MuiPaginationItem-root': {
                        fontFamily: "'Montserrat',sans-serif",
                    },
                }}
                slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay
                }}
                pageSizeOptions={[4]}
                checkboxSelection
                disableRowSelectionOnClick
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>{t('AdminEditBrand')}</h3>
                            <div style={{ width: '100%' }}>
                                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('AdminBrandName')}</label>
                                <input onChange={e=>setTitle(e.target.value)} style={{ width: 'calc(100% - 14px)', paddingLeft: '10px', fontFamily: '"Montserrat",sans-serif', height: "50px", borderRadius: '8px', border: '1px solid lightgrey', fontSize: '16px', fontWeight: '500' }} value={title} placeholder={t('AdminBrandName')} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('AdminPlatformName')}</label>
                                <input onChange={e=>setPlatformTitle(e.target.value)} style={{ width: 'calc(100% - 14px)', paddingLeft: '10px', fontFamily: '"Montserrat",sans-serif', height: "50px", borderRadius: '8px', border: '1px solid lightgrey', fontSize: '16px', fontWeight: '500' }} value={platformTitle} placeholder={t('AdminPlatformName')} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('AdminPlatformDomain')}</label>
                                <input onChange={e=>setPlatformDomain(e.target.value)} style={{ width: 'calc(100% - 14px)', paddingLeft: '10px', fontFamily: '"Montserrat",sans-serif', height: "50px", borderRadius: '8px', border: '1px solid lightgrey', fontSize: '16px', fontWeight: '500' }} value={platformDomain} placeholder={t('AdminPlatformDomain')} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('AdminBalance')}</label>
                                <FormControl
                                    color="warning" fullWidth sx={{
                                        width: '100%',
                                        '& .MuiTypography-root': {
                                            color: '#000',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'lightgrey',
                                                borderRadius: '8px'

                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'lightgrey',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#000',
                                            },
                                        },
                                    }}
                                >
                                    <OutlinedInput
                                        type="number"
                                        color="warning"
                                        sx={{
                                            color: "#000", fontFamily: "'Montserrat',sans-serif", appearance: 'none', "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                            "& input[type=number]": {
                                                color: "#000",
                                                MozAppearance: "textfield",
                                            },
                                        }}
                                        id="outlined-adornment-amount"
                                        startAdornment={<InputAdornment sx={{ color: "#fff", }} position="start">$</InputAdornment>}
                                        value={balance}
                                        onChange={(e)=> setBalance(e.target.value)}
                                        min={0}
                                    />
                                </FormControl>
                            </div>

                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <button onClick={Edit} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>{t('AdminEdit')}</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseModal()}
                                sx={{
                                    position: 'absolute',

                                    top: '10px',
                                    width: '40px',
                                    height: '40px',
                                    right: '10px',
                                    fill: '#000',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
        </>

    )
}