import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Backdrop, Fade, Modal, FormControl, InputAdornment, OutlinedInput, Checkbox} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} sx={{color:"#04948C",borderColor:"#04948C"}} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#171E29',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#171E29',
    width: "300px",
    color:'rgba(231, 227, 252, 0.87)'
};
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="200"
                height="160"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Empty set</Box>
        </StyledGridOverlay>
    );
}
function CustomToolbar({search,setSearch,t,handleOpenAddModal}) {
    return (
        <div style={secureLocalStorage.getItem('user').RoleId == 777 ?
        { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '20px' }
        : { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end', paddingTop: '20px' }
    }>
        {
            secureLocalStorage.getItem('user')?.RoleId == 777 ?
                <button onClick={handleOpenAddModal} style={{ border: 'none', backgroundColor: '#04948C', color: '#fff', padding: '15px 20px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '20px' }} variant="outlined" color="secondary" size="small" >
                    <AddCircleRoundedIcon /> ДОБАВИТЬ
                </button>
                : ''
        }
                        <div style={{ width: '250px' , marginRight:'20px' }}>
                            <label style={{ marginTop: '0', fontWeight: '500' }}>{t('ClientsGridSearch')}</label>
                            <FormControl
                                color="warning" fullWidth sx={{
                                    width: '100%',
                                    '& .MuiTypography-root': {
                                        color: '#fff',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'lightgrey',
                                            borderRadius: '8px'

                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#fff',
                                        },
                                    },
                                }}
                            >
                                <OutlinedInput
                                    type="text"
                                    sx={{
                                        color: "#fff", fontFamily: "'Montserrat',sans-serif", appearance: 'none', "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=text]": {
                                            color: "#fff",
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">
                                        <ManageSearchRoundedIcon sx={{color:'#fff'}} />
                                    </InputAdornment>}
                                    value={search}
                                    onChange={(e)=> setSearch(e.target.value)}
                                    min={0}
                                />
                            </FormControl>
                        </div>
        </div>
    );
}


export default function MasksDataGrid({handleClick,setSnackType,setSnackMessage,t }) {
    const [currentRow, setCurrentRow] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [search ,setSearch] = useState('')
    const [title ,setTitle] = useState('')
    const [masks,setMasks] = useState([])
    const [statuses,setStatuses] = useState([])
    const [networks,setNetworks] = useState([])
    const [status,setStatus] = useState()
    const [network,setNetwork] = useState()
    const [filteredMasks,setFilteredMasks] = useState([]);
    const handleOpenAddModal = () => {
        setOpenAddModal(true)
        setCurrentRow(null)
    };
    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setTitle('');
        setNetwork();
        setStatus()
    };
    const handleOpenModal = (row) => {
        setOpenModal(true)
        setCurrentRow(row)
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentRow(null)
    };
    useEffect(()=>{
        if(currentRow){
            setTitle(currentRow.Mask)
            setNetwork({value: currentRow.NetworkId , label:currentRow.Network})
            setStatus({value: currentRow.StatusId , label:currentRow.CrmStatus})
        }
        if(!currentRow){
            setTitle('')
            setNetwork()
            setStatus()
        }
    },[currentRow])
    const filterTable = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return masks;
        }
        return masks?.filter((article) =>
            article.Mask.toString().toLowerCase().includes(searchValue.toLowerCase())    
        );
    };
    useEffect(()=>{
        const fetchUsers = async ()=>{
            try{
                const {data} = await axios.get('https://www.leadhubcrm.pro/api/statuses/masks',{
                    headers: {
                      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                  })
                  const statuses = await axios.get('https://www.leadhubcrm.pro/api/statuses',{
                    headers: {
                      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                  })
                  const networks = await axios.get('https://www.leadhubcrm.pro/api/brands',{
                    headers: {
                      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                  })
                  
                  if(networks){
                    let temp = []
                    networks.data.forEach(el => {
                        temp.push({ value: el.Id, label: el.Title })
                    })
                    setNetworks(temp)
              }
                  if(statuses){
                        setStatuses(statuses.data.statuses)
                  }
                  if(data){
                    setMasks(data.masks)
                    setFilteredMasks(data.masks);
                  }
            }catch(e){
                handleClick();
                setSnackMessage(e.response.data.message);
                setSnackType("error"); 
            }
        }
        fetchUsers()
    },[])
    const columns = [
        {
            field: 'asdafaw',
            headerName: '',
            width: 20,
            editable: false,
            sortable: false,
        },
        {
            field: 'Id',
            headerName: 'ID',
            width: 210,
            editable: false,
            
        },
        {
            field:"Mask",
            headerName: 'BROKER STATUS',
            width: 150,
            editable: false,
        },
        {
            field: 'CrmStatus',
            headerName: 'CRM STATUS',
            width: 210,
            editable: false,
        },
        {
            field: 'Network',
            headerName: 'NETWORK',
            width: 160,
            editable: false,
        },
        {
            field: 'action',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 250,
            editable: false,
            type:'number',
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <>
                                        <button onClick={() => handleOpenModal(currentRow)} style={{ border: 'none', background: 'none', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }} variant="outlined" color="secondary" size="small" ><EditRoundedIcon /></button>
                                        {
                                            secureLocalStorage.getItem('user')?.RoleId === 777 && (
                                                <button onClick={() => DeleteMask(currentRow.Id)} style={{marginLeft:'10px', border: 'none', background: 'none', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }} variant="outlined" color="secondary" size="small" ><DeleteForeverRoundedIcon /></button>
                                            )
                                        }
                    </>

                );
            },
        },
    ];
    const DeleteMask = async(id) =>{
        Swal.fire({
            background: '#2B2D3A',
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            color: '#fff',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: 'rgba(255, 48, 48, 0.16)',
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            customClass: {
                title: '',
                text: 'text',
                cancelButton: 'Custom_Cancel_LightMode',
                confirmButton: 'Custom_Confirm_LightMode',
            }

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`https://www.leadhubcrm.pro/api/statuses/${id}`, {
                        headers: {
                            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        },
                    })
                    const masks = await axios.get('https://www.leadhubcrm.pro/api/statuses/masks', {
                        headers: {
                            'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                        }
                    })
                    if (masks) {
                        setMasks(masks.data.masks)
                        setFilteredMasks(masks.data.masks)
                    }
                    Swal.fire({
                        title: "Deleted!",
                        text: "Mask has been deleted.",
                        icon: "success",
                        background: '#2B2D3A',
                        color: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }

                    });
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        background: '#2B2D3A',
                        color: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }

                    });
                }
            }
        });
    }


    const Edit = async () =>{
        if (!title) {
            handleClick();
            setSnackMessage("Введите название статуса");
            setSnackType("error");
            return
        }
        if (!status) {
            handleClick();
            setSnackMessage("Выберите статус");
            setSnackType("error");
            return
        }
        if (!network) {
            handleClick();
            setSnackMessage("Выберите брокера");
            setSnackType("error");
            return
        }
        try{
            const edit = await axios.patch(`https://www.leadhubcrm.pro/api/statuses/${currentRow.Id}`, {
                mask: title,
                networkId: network.value,
                statusId:status.value
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
                if(edit){
                    const {data} = await axios.get('https://www.leadhubcrm.pro/api/statuses/masks',{
                        headers: {
                          Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        },
                      })
                      if(data){
                        setMasks(data.masks)
                        setFilteredMasks(data.masks)
                        handleCloseModal();
                        handleClick();
                        setSnackMessage("Cтатус изменён успешно");
                        setSnackType("success");
                      }
                }
        }catch(e){
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }


    useEffect(()=>{
        setFilteredMasks(filterTable(search));
    },[search])


    const AddStatus = async () =>{
        if (!title) {
            handleClick();
            setSnackMessage("Введите название статуса");
            setSnackType("error");
            return
        }
        if (!status) {
            handleClick();
            setSnackMessage("Выберите статус");
            setSnackType("error");
            return
        }
        if (!network) {
            handleClick();
            setSnackMessage("Выберите брокера");
            setSnackType("error");
            return
        }
        try{
            const newStatus = await axios.post(`https://www.leadhubcrm.pro/api/statuses/${status}`, {
                mask:title,
                networkId: network
            },
            {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
            })
            if(newStatus){
                const {data} = await axios.get('https://www.leadhubcrm.pro/api/statuses/masks',{
                    headers: {
                      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                  })
                  if(data){
                    setMasks(data.masks)
                    setFilteredMasks(data.masks)
                    handleCloseAddModal();
                    handleClick();
                    setSnackMessage("Cтатус добавлен успешно");
                    setSnackType("success");
                  }
            }
        }catch(e){
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");    
        }
    }
    return (
        <>
            <DataGrid
                rows={filteredMasks}
                autoHeight={true}
                columns={columns}
                getRowId={(row) => row.Id}       
                initialState={{
                    pagination: {

                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                sx={{
                    backgroundColor: '#171E29',
                    width: '100%',
                    minWidth: '400px',
                    color: "rgba(231, 227, 252, 0.87)",
                    fontSize: "12px",
                    border: 'none',
                    fontFamily: "'Montserrat',sans-serif",
                    height: '75vh',
                    '&.MuiDataGrid-root': { height: '75vh' },
                    "& .MuiDataGrid-virtualScroller ": {

                        overflowY: 'scroll !important'
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: "1px solid rgb(73, 72, 80)"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "1px solid rgb(73, 72, 80)"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    '& .MuiPaginationItem-root': {
                        fontFamily: "'Montserrat',sans-serif",
                    },
                    '& .MuiButtonBase-root': {
                        color: '#fff'
                    },
                    '& .MuiDataGrid-footerContainer': {
                        border: 'none'
                    },

                }}
                slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay,
                    toolbar : CustomToolbar,
                    baseCheckbox: (props)=>(
                        <Checkbox {...props} sx={{color:"#688CF2"}}/>
                    )
                }}
                pageSizeOptions={[100]}
                disableRowSelectionOnClick
                slotProps={{
                    toolbar: {
                        search: search,
                        setSearch: setSearch,
                        t:t,
                        handleOpenAddModal:handleOpenAddModal
                      }
                }}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>Добавить статус</h3>
                            <div style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
                                <label>Название</label>
                                <input value={title} onChange={e=> setTitle(e.target.value)} placeholder='Название' style={{ padding: '14.5px 10px', fontSize: '16px', fontFamily: '"Montserrat",sans-serif', background: '#121A21', border: "1px solid #B3b3b3", color: '#CCCAE0', borderRadius: '4px' }} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>Crm status</label>
                                <Select value={status} options={statuses} onChange={(e)=>{setStatus({value: e.value , label:e.label})}} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px',
                                        backgroundColor:'rgb(18,26,33)',
                                        overflow:'hidden',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500',
                                        color:'rgba(231, 227, 252, 0.87)'
                                    }),
                                    option:(baseStyles , state)=>({
                                        ...baseStyles,
                                        backgroundColor:'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    input:(baseStyles , state)=>({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    placeholder: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'

                                    }),
                                    }} placeholder={'Crm status'}></Select>
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>Network</label>
                                <Select value={network} options={networks} onChange={(e)=>{setNetwork({value: e.value , label:e.label})}} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px',
                                        backgroundColor:'rgb(18,26,33)',
                                        overflow:'hidden',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500',
                                        color:'rgba(231, 227, 252, 0.87)'
                                    }),
                                    option:(baseStyles , state)=>({
                                        ...baseStyles,
                                        backgroundColor:'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    input:(baseStyles , state)=>({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    placeholder: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'

                                    }),
                                    }} placeholder={'Network'}></Select>
                            </div>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <button onClick={Edit} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>Изменить</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseModal()}
                                sx={{
                                    position: 'absolute',

                                    top: '10px',
                                    width: '40px',
                                    height: '40px',
                                    right: '10px',
                                    fill: '#fff',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAddModal}
                onClose={handleCloseAddModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openAddModal}>
                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>Добавить статус</h3>
                            <div style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
                                <label>Название</label>
                                <input onChange={e=> setTitle(e.target.value)} placeholder='Название' style={{ padding: '14.5px 10px', fontSize: '16px', fontFamily: '"Montserrat",sans-serif', background: '#121A21', border: "1px solid #B3b3b3", color: '#CCCAE0', borderRadius: '4px' }} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>Crm status</label>
                                <Select options={statuses} onChange={(e)=>{setStatus(e.value)}} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px',
                                        backgroundColor:'rgb(18,26,33)',
                                        overflow:'hidden',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500',
                                        color:'rgba(231, 227, 252, 0.87)'
                                    }),
                                    option:(baseStyles , state)=>({
                                        ...baseStyles,
                                        backgroundColor:'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    input:(baseStyles , state)=>({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    placeholder: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'

                                    }),
                                    }} placeholder={'Crm status'}></Select>
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>Network</label>
                                <Select options={networks} onChange={(e)=>{setNetwork(e.value)}} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px',
                                        backgroundColor:'rgb(18,26,33)',
                                        overflow:'hidden',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500',
                                        color:'rgba(231, 227, 252, 0.87)'
                                    }),
                                    option:(baseStyles , state)=>({
                                        ...baseStyles,
                                        backgroundColor:'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    input:(baseStyles , state)=>({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    placeholder: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'

                                    }),
                                    }} placeholder={'Network'}></Select>
                            </div>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <button onClick={AddStatus} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>Добавить</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseAddModal()}
                                sx={{
                                    position: 'absolute',

                                    top: '10px',
                                    width: '40px',
                                    height: '40px',
                                    right: '10px',
                                    fill: '#fff',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
        </>

    )
}