import styles from './Affiliate.module.scss'
import NavBar from '../../components/NavBar';
import { useTranslation } from 'react-i18next';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Countries from '../../resources/countries.json';
import secureLocalStorage from 'react-secure-storage';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
export default function Affiliate({handleClick,setSnackType,setSnackMessage,isSmall , setSmall}){
    const {t} = useTranslation()
    const [countries,SetCountries] = useState([]);
    useEffect(()=>{
        axios.get(`https://www.leadhubcrm.pro/api/flows/${secureLocalStorage.getItem('user').Id}`, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                }).then((res)=>{
                    SetCountries(res.data);
                })
    },[])
    return(
        <div className={styles.tables}>
            <NavBar isSmall={isSmall} setSmall={setSmall}/>
            <div className={styles.content}  style={isSmall?{marginLeft:'7.5%'}:{marginLeft:'19.5%'}}>
                <div className={styles.topContent}>
                    <h1>Аффилиат</h1>
                </div>
                <div className={styles.DataGrid} >
                    <div style={{fontSize:"20px",fontWeight:"600"}}>{secureLocalStorage.getItem('user').Login}</div>
                    <br/>
                    <div 
                    style={{
                        color:"rgb(109, 222, 243)",
                        backgroundColor:"rgba(109, 222, 243,0.5)",
                        width:'200px',
                        padding:5,
                        fontWeight:"600",
                        gap:"10px",
                        display:"flex",
                        flexDirection:'row',
                        alignItems:'center',
                        borderRadius:"7px",
                        
                    }}
                    >
                       <ManageAccountsRoundedIcon/> AffiliateId: {secureLocalStorage.getItem('user').Id}
                    </div>
                    <br/>
                    {
                    countries.length>0?
                    <>
                    <span>Страны для отправки лидов:</span>
                    <div style={{display:"flex",flexDirection:'column',gap:"10px",alignItems:'start',fontFamily:"'Montserrat',sans-serif",  width:"290px",borderRadius:"10px",marginTop:"10px",padding:"5px"}}>
                    {countries.map((value) => (
                        <div style={{display:"flex",flexDirection:'row',gap:"10px",alignItems:'center',fontFamily:"'Montserrat',sans-serif"}}>
                            <IconButton aria-label="comment">
                                <img src={Countries.find((obj)=>obj.name.official==value.Country ||obj.name.common==value.Country || obj.altSpellings.includes(value.Country))?.flags?.png} alt={Countries.find((obj)=>obj.name.common==value.Country)?.flags?.alt} height={24} className="me-2" width={24} style={{borderRadius:'50%'}} />
                            </IconButton>
                            <span>
                            {`${value.Country}`}
                            </span>
                        </div>
                    ))}
                    </div>
                    </>
                    : <span>Ваш поток не имеет доступных стран. Обратитесь к администрации.</span>
                    }
                </div>
            </div>
        </div>
    )
}